import React from 'react'
import { cn } from '~/utils/cn'

export type BurgerProps = {
  onClick?: () => void
  isOpened?: boolean
  className?: HTMLButtonElement['className']
}

const Burger = ({
  onClick = undefined,
  isOpened = false,
  className = ''
}: BurgerProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onClick?.()
  }

  return (
    <button
      aria-controls="secondary-menu"
      type="button"
      aria-expanded={isOpened}
      aria-label={isOpened ? 'Fermer le menu' : 'Ouvrir le menu'}
      className={cn(
        'flex size-11 cursor-pointer items-center justify-center text-current',
        className,
        {
          'flex flex-col gap-2': !isOpened
        }
      )}
      onClick={handleClick}
      data-testid="burgerMenu"
    >
      <div className="relative box-content h-[16px] w-[16px]">
        <span
          className={cn(
            'absolute left-0 right-0 top-1/2 mx-auto -mt-[1px] mb-0 block h-[2px] w-full translate-y-[calc(16px/-3)] bg-current transition-[transform]',
            { 'translate-y-0 rotate-45': isOpened }
          )}
        />
        <span
          className={cn(
            'absolute left-0 right-0 top-1/2 mx-auto -mt-[1px] mb-0 block h-[2px] w-full bg-current',
            {
              hidden: isOpened
            }
          )}
        />
        <span
          className={cn(
            'absolute left-0 right-0 top-1/2 mx-auto -mt-[1px] mb-0 block h-[2px] w-full translate-y-[calc(16px/3)] bg-current transition-[transform]',
            { 'translate-y-0 rotate-[-45deg]': isOpened }
          )}
        />
      </div>
    </button>
  )
}

export default Burger
